import React from 'react';

export default class Forum2023 extends React.Component {
    componentDidMount() {
        window.location.href = 'https://forms.gle/D3DuwKTFiwdTgtTG7';
    }

    render() {
        return (
            <div></div>
        );
    }
}